// Yes, this is global state, but couldn't find another method
// the sentry client should only be created once when starting the server
// and could be used in all other places to log errors
import debugLib from 'debug';
import { ExtraErrorData } from '@sentry/integrations';
import isLocalOrDevelopment from '../../../server/util/isLocalOrDevelopment';
import {
  ignoreXhrError,
  buildXhrFingerprint,
  getXhrErrorMessage,
  isQuotaExceededError,
} from './helpers';
import GatewayError from '../../net/gateway/GatewayError';

const debug = debugLib('SlimmingWorld:SentryClient');

let sentryClient = null;

/** @module */

/**
 * Gets either the server or browser version of the Sentry client
 * Can be called from any isomorphic code
 * @return {*}
 */
export function getClient() {
  return sentryClient;
}

/**
 * Create/config a sentry client for nodejs.
 *
 * @function createClientOnServer
 * @param sentry The nodejs sentry client
 * @param sentryConfig The nodejs sentry config
 * @param buildInfo Will be added to all exceptions to track in which builds the errors
 * are happening
 * @return {*}
 * @category tracking
 */
export function createClientOnServer(sentry, sentryConfig, buildInfo) {
  if (isLocalOrDevelopment()) {
    return null;
  }

  sentry.init({
    dsn: sentryConfig.dsn,
    release: buildInfo.release,
    commit: buildInfo.commit,
    branch: buildInfo.branch,
    tag: buildInfo.tag,
    fingerprint: ['{{ default }}', process.env.NODE_ENV],
    beforeSend,
    integrations: [new ExtraErrorData()],
    environment: '-',
    initialScope: {
      tags: {
        env: process.env.NODE_ENV,
        commit: buildInfo.commit,
        branch: buildInfo.branch,
        tag: buildInfo.tag,
      },
    },
  });

  sentry.setContext({
    tags: {
      env: process.env.NODE_ENV,
    },
  });

  sentryClient = sentry;

  return sentryClient;
}

/**
 * Create/config a sentry client for the browser.
 *
 * @function configureSentryOnClient
 * @param sentry The javascript sentry client
 * @param sentryConfig The nodejs sentry config
 * @param buildInfo Will be added to all exceptions to track in which builds the errors
 * are happening
 * @category tracking
 */
export function configureSentryOnClient(sentry, sentryConfig, buildInfo) {
  if (isLocalOrDevelopment()) {
    return;
  }

  sentry.init({
    dsn: sentryConfig.dsn,
    // we highly recommend restricting exceptions to a domain in order to filter out clutter
    allowUrls: sentryConfig.whitelistUrls.split(','),
    ignoreErrors: [
      // Random plugins/extensions
      'top.GLOBALS',
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error. html
      'originalCreateNotification',
      'canvas.contentDocument',
      'MyApp_RemoveAllHighlights',
      'http://tt.epicplay.com',
      "Can't find variable: ZiteReader",
      'jigsaw is not defined',
      'ComboSearch is not defined',
      'http://loading.retry.widdit.com/',
      'atomicFindClose',
      // Facebook borked
      'fb_xd_fragment',
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
      // reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      'conduitPage',
      /^Access token required, redirecting to sign in$/,
    ],
    denyUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    ],
    release: buildInfo.release,
    commit: buildInfo.commit,
    branch: buildInfo.branch,
    tag: buildInfo.tag,
    environment: '-',
    beforeSend,
    integrations: [new ExtraErrorData()],
    initialScope: {
      tags: {
        commit: buildInfo.commit,
        branch: buildInfo.branch,
        tag: buildInfo.tag,
      },
    },
  });

  sentryClient = sentry;
}

/* eslint-disable no-param-reassign */
function beforeSend(event, hint) {
  try {
    const exception = hint.originalException;

    if (exception instanceof GatewayError) {
      event.fingerprint = buildXhrFingerprint(exception);
    }

    if (isQuotaExceededError(exception)) {
      event.fingerprint = ['{{ default }}', 'QuotaExceededError'];
      if (exception.message.includes('oidc.')) {
        event.fingerprint.push('OIDC');
      }

      if (event.exception.values[0]) {
        // Retain original error message in extra data
        event.extra = {
          ...(event.extra ?? {}),
          originalException: exception.message,
        };

        // Amend original exception message so that it is not involved in aggregation (it typically includes unique, event specific values)
        event.exception.values[0].value =
          'QuotaExceededError: An attempt was made to add something to storage that exceeded the quota';
      }
    }

    // Remove references to the environment
    if (event.request?.env) {
      delete event.request.env;
    }
  } catch {
    // Ensure error is always logged to Sentry
  }

  return event;
}
/* eslint-enable no-param-reassign */

export const disallowList = {
  'weigh-ins/current': [404],
  'competition-entries/banner': [404],
  favourites: [404],
  'accept-lock': [202],
  features: [404],
  groups: [404],
  'food-guides': [404],
  'wearable-devices': [404],
  'auto-renewals': [404],
};

/**
 * Used for capturing XHR errors from any of the API Gateways
 * @param error
 */
export function captureXhrError(error) {
  const client = getClient();
  if (client) {
    const errorEndpointsToLog = ['profiles', 'incompleteprofiles'];

    // hide all 4xx errors (Client Error), and others that are blacklisted above
    if (!ignoreXhrError({ error, disallowList, errorEndpointsToLog })) {
      const errorMessage = getXhrErrorMessage(error);

      client.captureMessage(`[XHR Error] ${errorMessage}`, {
        extra: {
          requestMethod: error.request.method,
          requestUrl: error.request.url,
          requestBody: error.request.body,
          responseStatus: error.response.status,
          responseBody: error.response.text?.substring(0, 1024) || '[empty]',
          errorEncountered: errorMessage,
        },
        fingerprint: buildXhrFingerprint(error),
      });
    } else {
      debug('[Sentry] Ignored error: %o', error);
    }
  } else {
    debug('[Sentry not enabled] %o', error);
  }
}
