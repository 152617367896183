import React, { useEffect } from 'react';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import Pages from 'common/src/app/data/enum/Pages';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import TextNew, { ElementTypes } from 'components/atoms/TextNew';
import AdyenConfig from 'common/src/app/adyen/types/AdyenConfig.d';
import PackageSummary from 'components/molecules/PackageSummary';
import AdyenPayment from 'components/organisms/AdyenPayment';
import { AdyenTransactionResultCode } from 'common/src/app/adyen/types/AdyenTransactionResultCode';
import './payment-form.scss';

export type PaymentFormProps = {
  getPackagesForRegion: (regionCode: number) => void;
  accountHost: string;
  paymentSuccessRedirect: (purchaseId: string | null, packageType: string) => void;
  paymentResponse: (purchaseId: string | null, resultCode: AdyenTransactionResultCode) => void;
  onlineRegistrationRedirect: () => void;
  purchaseInfo: {
    region: number;
    purchaseId: string;
    package: string;
  };
  selectedPackage?: {
    name: string;
  };
};

declare let WP_DEFINE_DEVELOPMENT: string | undefined;

const PaymentForm = (
  {
    getPackagesForRegion,
    accountHost,
    selectedPackage,
    purchaseInfo,
    paymentSuccessRedirect,
    paymentResponse,
    onlineRegistrationRedirect,
  }: PaymentFormProps,
  {},
  className: string,
): JSX.Element => {
  const purchaseId = purchaseInfo?.purchaseId || null;
  const packageType = purchaseInfo?.package;
  const region = purchaseInfo?.region;

  useEffect(() => {
    !selectedPackage && region && getPackagesForRegion(region);
  }, [selectedPackage, region, getPackagesForRegion]);

  const redirectToPaymentSuccessPage = () => paymentSuccessRedirect(purchaseId, packageType);

  const pageUrl = WP_DEFINE_DEVELOPMENT
    ? `http://localhost:3002${Pages.REGISTRATION_PAYMENT}`
    : `${accountHost}${Pages.REGISTRATION_PAYMENT}`;

  return (
    <div className={className}>
      <TextNew
        cid="header"
        family="serif"
        size="lg"
        element={ElementTypes.H1}
        localeId="adyenPaymentError.title"
      />

      {purchaseId && (
        <AdyenPayment
          pageUrl={pageUrl}
          purchaseId={purchaseId}
          paymentResponse={paymentResponse}
          redirectToPaymentSuccessPage={redirectToPaymentSuccessPage}
          fallbackRedirect={onlineRegistrationRedirect}
        >
          {(config: AdyenConfig) => (
            <PackageSummary
              packageName={selectedPackage?.name}
              amount={config.session.amount.value}
              currency={config.session.amount.currency}
            />
          )}
        </AdyenPayment>
      )}
    </div>
  );
};

export default withDeviceState()(
  withFunctionalClassName(ComponentType.TEMPLATE, 'PaymentForm')(PaymentForm),
);
