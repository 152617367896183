import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import Colors from 'common/src/app/data/enum/Colors';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import { ProgressIndicator } from '@slimming-world/ui-library-components';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import Header from '../Header';
import PublicNavigationLinks from '../../molecules/PublicNavigationLinks';
import BackLink from '../../molecules/BackLink';
import Wrapper from '../../atoms/Wrapper';
import SlimmingWorldLogo from '../../atoms/SlimmingWorldLogo';

import './registration-template.scss';

const TIMEOUT = 250;

const RegistrationTemplate = (
  { deviceState, publicHost, children, pageDetails, lastMountedStepIndex, groupRegistrationCTAs },
  _context,
  className,
) => {
  const [cookieBannerHeight, setCookieBannerHeight] = useState(0);
  let timer;

  /* We need to dynamically get the height of the OneTrust cookie banner, which we then use as
    margin-bottom in the registration template, so that the cookie banner doesn't block the CTA's
    in the registration flow. */
  const getCookieBannerHeight = debounce(() => {
    if (window?.OneTrust?.IsAlertBoxClosed() === false) {
      const cookieBanner = document.getElementById('onetrust-banner-sdk');
      if (cookieBanner) {
        setCookieBannerHeight(cookieBanner.getBoundingClientRect().height);
      } else {
        // Sometimes the cookie bar isn't loaded in the DOM yet, so we try again here
        timer = setTimeout(getCookieBannerHeight, TIMEOUT);
      }
    } else {
      setCookieBannerHeight(0);
    }
  }, TIMEOUT);

  useEffect(() => {
    getCookieBannerHeight();

    window.addEventListener('resize', getCookieBannerHeight);

    if (typeof window?.OneTrust?.OnConsentChanged === 'function') {
      // eslint-disable-next-line new-cap, no-unused-expressions
      window.OneTrust.OnConsentChanged(() => setCookieBannerHeight(0));
    }

    return () => {
      window.removeEventListener('resize', getCookieBannerHeight);
      clearInterval(timer);
    };
  }, [timer, getCookieBannerHeight]);

  return (
    <div className={className} style={{ marginBottom: cookieBannerHeight }}>
      <Header
        center={<SlimmingWorldLogo link={publicHost} />}
        bottom={
          pageDetails && (
            <>
              <BackLink labelVisible={deviceState > DeviceState.LG} />
              <ProgressIndicator
                className="progress-indicator"
                width={220}
                percent={Math.round((lastMountedStepIndex / pageDetails.length) * 100)}
              />
            </>
          )
        }
      >
        <PublicNavigationLinks />
      </Header>
      {children && (
        <Wrapper
          width="ls"
          background={Colors.PRIMARY_LIGHT}
          padding="xxl"
          cid="registration-content"
        >
          {children}
          {groupRegistrationCTAs && groupRegistrationCTAs}
        </Wrapper>
      )}
    </div>
  );
};

RegistrationTemplate.propTypes = {
  children: PropTypes.node,
  deviceState: PropTypes.number,
  lastMountedStepIndex: PropTypes.number.isRequired,
  publicHost: PropTypes.string.isRequired,
  pageDetails: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  groupRegistrationCTAs: PropTypes.node,
};

RegistrationTemplate.defaultProps = {
  showNav: true,
};

export default withDeviceState()(
  withFunctionalClassName(ComponentType.ORGANISM, 'RegistrationTemplate')(RegistrationTemplate),
);
