import React from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import EnhancedField from 'common/src/app/enhanced-redux-form/components/EnhancedField';
import { functionalComponentClassName } from 'common/src/app/util/componentClassNameUtils';
import Configuration from 'common/src/app/config/Configuration';
import { handleFieldGroupInput } from 'common/src/app/util/form/inputEventUtil';
import LocaleMessage from '../../atoms/LocaleMessage';
import Row from '../../atoms/Row';
import Input, { descriptorTypes } from '../../atoms/Input';
import Select from '../../atoms/Select';
import TextNew from '../../atoms/TextNew';

import './weight-imperial-field-group.scss';

const FULL_POUND_LENGTH = 3;
const HALF_POUND_LENGTH = 2;

const WeightImperialFieldGroup = (
  { abbreviate, selectArrowType, disabled, hidePlaceholder, hideStonesUsePounds, onFieldFocus },
  { getMessage },
) => {
  let stoneAbbreviation = '';
  if (Configuration.imperialWeightUseStones && abbreviate) {
    stoneAbbreviation = <LocaleMessage id="general.measureUnit.weight.pounds.abbreviation" />;
  } else if (Configuration.imperialWeightUseStones) {
    stoneAbbreviation = <LocaleMessage id="general.measureUnit.weight.pounds.name" />;
  }

  let fullPoundsPlaceholder = Configuration.imperialWeightUseStones ? '00' : '000';
  let remainingPoundsStonesPlaceholder = '00';

  if (hidePlaceholder) {
    fullPoundsPlaceholder = ' ';
    remainingPoundsStonesPlaceholder = ' ';
  }
  return (
    <Row
      className={functionalComponentClassName(ComponentType.MOLECULE, 'WeightImperialFieldGroup')}
    >
      {Configuration.imperialWeightUseStones &&
        !hideStonesUsePounds && [
          <EnhancedField
            component={Input}
            key={getMessage('general.measureUnit.weight.stone.label')}
            name={getMessage('general.measureUnit.weight.stone.label')}
            id="_composite-input-initialWeight.stones"
            descriptorType={descriptorTypes.STATIC}
            type="number"
            min="0"
            max="50"
            onFocus={onFieldFocus && onFieldFocus}
            ariaLabel={getMessage('general.measureUnit.weight.stone.label')}
            placeholderAbbreviation={
              <LocaleMessage
                id={`general.measureUnit.weight.stone.${
                  abbreviate ? 'labelAbbreviation' : 'label'
                }`}
              />
            }
            placeholder={remainingPoundsStonesPlaceholder}
            disabled={disabled}
          />,
          <TextNew.Formal key="dot" cid="dot" aria-hidden="true">
            .
          </TextNew.Formal>,
        ]}

      <EnhancedField
        component={Input}
        id="_composite-input-initialWeight.fullPounds"
        name="fullPounds"
        descriptorType={descriptorTypes.STATIC}
        type="number"
        placeholder={fullPoundsPlaceholder}
        max={Configuration.imperialWeightUseStones ? '13' : '999'}
        ariaLabel={getMessage('general.measureUnit.weight.pounds.label')}
        onKeyPress={event => handleFieldGroupInput(event, FULL_POUND_LENGTH)}
        onFocus={onFieldFocus && onFieldFocus}
        placeholderAbbreviation={stoneAbbreviation}
        disabled={disabled}
      />

      {Configuration.imperialWeightUseStones ? (
        <EnhancedField
          component={Select}
          arrowType={selectArrowType}
          name="remainingPounds"
          customLabel="½"
          title="½"
          onFocus={onFieldFocus && onFieldFocus}
          options={[
            {
              title: '0',
              value: '0',
              ariaLabel: getMessage('general.measureUnit.weight.pounds.labelZeroPound'),
            },
            {
              title: '½',
              value: '0.5',
              ariaLabel: getMessage('general.measureUnit.weight.pounds.labelHalfPound'),
            },
          ]}
          disabled={disabled}
        />
      ) : (
        [
          <TextNew.Formal key="dot" cid="dot" aria-hidden="true">
            .
          </TextNew.Formal>,
          <EnhancedField
            key="remainingPounds"
            component={Input}
            name="remainingPounds"
            descriptorType={descriptorTypes.STATIC}
            type="number"
            placeholder={remainingPoundsStonesPlaceholder}
            placeholderAbbreviation={
              <LocaleMessage
                id={`general.measureUnit.weight.pounds.${abbreviate ? 'abbreviation' : 'name'}`}
              />
            }
            min="0"
            max="1"
            onFocus={onFieldFocus && onFieldFocus}
            onKeyPress={event => handleFieldGroupInput(event, HALF_POUND_LENGTH)}
            disabled={disabled}
          />,
        ]
      )}
    </Row>
  );
};

WeightImperialFieldGroup.contextTypes = {
  getMessage: PropTypes.func.isRequired,
};

WeightImperialFieldGroup.propTypes = {
  disabled: PropTypes.bool,
  abbreviate: PropTypes.bool,
  selectArrowType: PropTypes.string,
  hidePlaceholder: PropTypes.bool,
  hideStonesUsePounds: PropTypes.bool,
  onFieldFocus: PropTypes.func,
};

export default WeightImperialFieldGroup;
