import { createSelector } from 'reselect';
import orderBy from 'lodash/orderBy';
import { hasFlag, convertArrayToBitwise } from 'common/src/app/util/bitwiseUtils';
import { AwardMap } from 'common/src/app/data/enum/AwardType';

import ProfileEvents from 'common/src/app/data/enum/ProfileEvents';
import { PROFILE, PRIVACY_SETTINGS, USER_WEIGH_IN_HISTORY } from '../data/entityTypes';
import { userIdSelector } from './userAccountSelectors';
import { userWeighInsCollectionId } from '../data/collectionIds';
import { makeCollectionSelector } from './collectionSelector';
import { dietaryFields } from '../data/enum/FieldNames/AccountFieldNames';

import Medical from '../data/enum/Medical';

/**
 * Selector that receives the root redux state and returns an object with user profile.
 * If the user is not logged in, or if the user is logged in but the profile entity is
 * not loaded from the API, this selector returns null.
 */
export const userProfileSelector = createSelector(
  userIdSelector,
  state => state.entities[PROFILE] || {},
  (userId, profileEntities) => {
    if (userId && profileEntities[userId]) {
      return profileEntities[userId];
    }
    return null;
  },
);

/**
 * TEMP helper function, remove this once we implement correct profile > weighins normalisation
 * @param state
 */
export const makeWeighInsSelector = () => {
  const collectionSelector = makeCollectionSelector();
  return createSelector(
    (state, props) => {
      const userId = userIdSelector(state, props);

      return collectionSelector(state, { collectionId: userWeighInsCollectionId({ userId }) })
        .entities;
    },
    userProfileSelector,
    (weighInEntities, profile) => {
      if (weighInEntities) {
        return weighInEntities;
      }
      if (profile) {
        return profile.weighIns;
      }
      return [];
    },
  );
};

const weighInSelector = makeWeighInsSelector();

/**
 * Instance of weighInSelector that does not pass props, and thus will always get the weighins
 * for the current user.
 * @param state
 * @returns {*}
 */
export const userWeighInsSelector = state => weighInSelector(state);

export const getPrivacySelector = createSelector(
  state => state.entities[PRIVACY_SETTINGS] || null,
  userIdSelector,
  (privacyEntity, userId) => (privacyEntity ? privacyEntity[userId] : null),
);

export const getJourneyStartWeightSelector = createSelector(
  userProfileSelector,
  profile => profile && profile.currentJourney && profile.currentJourney.startWeight,
);

export const getJourneyIdSelector = createSelector(
  userProfileSelector,
  profile => profile?.currentJourney?.id,
);

/**
 * Selector that gets the current weight of the user. It first checks the most recent weighIn,
 * and will fall back to the startWeight when there are no weighIns yet.
 */
export const currentWeightSelector = createSelector(
  userProfileSelector,
  profile => profile && profile.currentJourney && profile.currentJourney.currentWeight,
);

export const userProfileHeightSelector = createSelector(
  userProfileSelector,
  profile => profile?.height,
);

export const userProfileCurrentWeightSelector = createSelector(
  userProfileSelector,
  profile => profile?.currentWeight,
);

/**
 * Selector for selected weigh-in by id
 */
const weighInByIdSelector = createSelector(
  state => state.weighIn.weighInById,
  weighInById => {
    if (weighInById) {
      return weighInById;
    }
    return null;
  },
);

/**
 * Selector for weigh-in to add
 */
export const weighInToAddSelector = createSelector(
  state => state.weighIn.weighInToAdd,
  weighInToAdd => {
    if (weighInToAdd) {
      return weighInToAdd;
    }
    return null;
  },
);

/**
 * Select weigh-in history
 */
export const weighInHistorySelector = createSelector(
  state => state.entities[USER_WEIGH_IN_HISTORY],
  weighInHistory => {
    if (weighInHistory) {
      return Object.values(weighInHistory);
    }
    return [];
  },
);

/**
 * Selector that gets the previous weight of the after having done a new weighin.
 * It first checks the second most recent weighIn, and will fall back to the initialWeight when
 * there are no weighIns yet.
 *
 */
export const previousWeightSelector = createSelector(
  userWeighInsSelector,
  getJourneyStartWeightSelector,
  weighInHistorySelector,
  weighInByIdSelector,
  weighInToAddSelector,
  (weighIns, journeyStartWeight, weighInHistory, weighInById, weighInToAdd) => {
    const weighInWeights = weighIns && weighIns.filter(weighIn => !weighIn.skippingReason);

    // Do a correct filter to get the correct previous weight, based on the weight you are editing or adding
    const weighInToCheck = weighInById || weighInToAdd;

    if (weighInToCheck && weighInHistory && weighInHistory.length > 1) {
      const filteredWeighInHistory = weighInHistory.filter(weighIn => !weighIn.skippingReason);
      const orderByDateWeighIns = orderBy(
        filteredWeighInHistory,
        weighIn => weighIn.startOfUserWeekUtc,
        ['desc'],
      );

      // Find the weigh-in index from the orderByDateWeighIns
      const index = orderByDateWeighIns.findIndex(fd => weighInToCheck.id === fd.id);
      const previousWeighIn = orderByDateWeighIns
        .slice(index)
        .find(
          validWeighIn => validWeighIn.weighingDateUTC && validWeighIn.id !== weighInToCheck.id,
        );

      if (previousWeighIn) {
        return previousWeighIn.weight;
      }

      return journeyStartWeight;
    }
    if (weighInWeights && weighInWeights.length > 1) {
      return weighInWeights[1].weight;
    }

    return journeyStartWeight;
  },
);

/**
 * This combined selector is used for showing the correct current week number and mood on
 * the progress tile
 */
export const lastWeighInSelector = createSelector(
  userWeighInsSelector,
  weighIns => weighIns?.[0] || null,
);

/**
 * This selector finds if a user has completed a first weigh in yet
 */
export const isFirstWeighInSelector = createSelector(
  userWeighInsSelector,
  weighIns => weighIns && weighIns.length === 0,
);

/**
 * Selector that determines if a member has either the vegan or vegetarian dietary preference set
 */
export const isVeganOrVegetarianSelector = createSelector(userProfileSelector, profile => {
  if (profile?.diet) {
    return (
      hasFlag(profile.diet, convertArrayToBitwise([dietaryFields.VEGETARIAN])) ||
      hasFlag(profile.diet, convertArrayToBitwise([dietaryFields.VEGAN]))
    );
  }

  return false;
});

/**
 * Selector that determines if a member has the exclude red meat dietary preference set
 */
export const isExcludingRedMeatSelector = createSelector(userProfileSelector, profile => {
  if (profile?.diet) {
    return hasFlag(profile.diet, convertArrayToBitwise([dietaryFields.EXCLUDE_RED_MEAT]));
  }

  return false;
});

/**
 * Selector that determines if a member already has the Food Optimising hero award
 */
export const hasFoodOptimisingAwardSelector = createSelector(userProfileSelector, profile => {
  if (profile?.awards) {
    return !!profile.awards.some(item => item?.type?.id === AwardMap.FOOD_OPTIMISING_AWARD);
  }

  return false;
});

/**
 * Selector that determines if a member has the vegan dietary preference set
 */
export const isVeganSelector = createSelector(userProfileSelector, profile => {
  if (profile?.diet) {
    return hasFlag(profile.diet, convertArrayToBitwise([dietaryFields.VEGAN]));
  }

  return false;
});

/**
 * Selector that determines if a member has the vegetarian dietary preference set
 */
export const isVegetarianSelector = createSelector(userProfileSelector, profile => {
  if (profile?.diet) {
    return hasFlag(profile.diet, convertArrayToBitwise([dietaryFields.VEGETARIAN]));
  }

  return false;
});

export const pregnancyAndBreastfeedingBitwiseValueSelector = createSelector(
  [userProfileSelector],
  ({ isBreastfeeding, isPregnant }) =>
    (isBreastfeeding ? Medical.breastFeeding : 0) + (isPregnant ? Medical.pregnant : 0),
);

/**
 * Selector that determines if a member has the: forcedToStartSTSFoodOptimisingModule event
 */
export const memberHasTheIsForcedToStartedTheModuleUserEvent = createSelector(
  userProfileSelector,
  profile => {
    if (profile?.userEvents) {
      return !!profile.userEvents.some(
        event => event?.userEventType === ProfileEvents.FORCED_TO_START_STS_FOOD_OPTIMISING,
      );
    }

    return false;
  },
);

/**
 * Selector that determines if a member has the: completedSTSFoodOptimisingModule event
 */
export const memberHasTheCompletedFoodOptimisingModuleUserEvent = createSelector(
  userProfileSelector,
  profile => {
    if (profile?.userEvents) {
      return !!profile.userEvents.some(
        event => event?.userEventType === ProfileEvents.COMPLETED_STS_FOOD_OPTIMISING,
      );
    }

    return false;
  },
);

/**
 * Selector that gets the current journey of the user.
 */
export const currentJourneySelector = createSelector(
  userProfileSelector,
  profile => profile?.currentJourney,
);

/**
 * Selector that gets the flag that determines whether the profile is blocked by the requesting user.
 */
export const isBlockedSelector = createSelector(userProfileSelector, profile => profile?.isBlocked);

/**
 * Selector that returns the user events for the member
 */
export const userEventsSelector = createSelector(
  userProfileSelector,
  profile => profile?.userEvents,
);
