import { useEffect, RefObject } from 'react';

const useScrollToElement = (
  ref: RefObject<HTMLElement>,
  shouldScroll: boolean,
  options?: ScrollIntoViewOptions,
) => {
  useEffect(() => {
    if (shouldScroll && ref.current) {
      const scrollOptions: ScrollIntoViewOptions = {
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
        ...options,
      };
      ref.current.scrollIntoView(scrollOptions);
    }
  }, [ref, shouldScroll, options]);
};

export default useScrollToElement;
