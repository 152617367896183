import { HEALTHY_EXTRAS } from './collectionPaginationViews';
import HealthyExtrasFields from './enum/FieldNames/HealthyExtrasFields';
import { allFilterKeys } from '../util/filterConfig';

/**
 * Contains ids for collections (lists of entities).
 * Some collections have no parameters and are exported as constant strings. Collections that
 * have parameters are exported as functions that receive parameters and return a string id.
 * @category redux-state
 * @module collectionIds
 */

/**
 * A wildcard collection id. When this is used instead of a collection id, the action will
 * affect all collections which are currently in state
 * @type {string}
 */
export const COLLECTION_WILDCARD = '*';

/**
 * Returns a function that generates a collection id with the given collection name and
 * parameters.
 * @function parameterizedCollectionId
 * @param collectionName {string} the name of the collection
 * @param paramNames {Array<string>} the names of the parameters
 * @returns {function} A function that accepts an object with parameters and returns a string
 * collection ID.
 * @example export const fooCollectionId = parameterizedCollectionId(
 *   'foo',
 *   ['sort', 'category']
 * );
 *
 * // usage, get collection id for foo collection with sort==1 and category==10
 * fooCollectionId({ sort: 1, category: 10 });
 * // usage, foo collection with wildcard, applies to all foo collections regardless of parameters
 * fooCollectionId();
 */
export const parameterizedCollectionId = (collectionName, paramNames) => params =>
  collectionName +
  (params ? `[${paramNames.map(name => params[name] || '').join('&')}]` : COLLECTION_WILDCARD);

/**
 * User notifications (globally available)
 * @type {string}
 */
export const NOTIFICATIONS = 'notifications';

/**
 * User bookmarks
 */
export const BOOKMARKS = 'bookmarks';

/**
 * Tags in Community
 * @type {string}
 */
export const COMMUNITY_TAGS = 'communityTags';

/**
 * The collection of posts visible on the CommunityOverview page
 * @function communityPostsCollectionId
 * @param params {object}
 * @param [params.tags] {number} A number representing the active tag filters (bitwise flag)
 * @param [params.onlyCurrentUser] {boolean} `true` if the 'only me' filter is active
 * @param [params.sort] {string} The sorting type used
 */
export const communityPostsCollectionId = parameterizedCollectionId('communityPosts', [
  'tags',
  'onlyCurrentUser',
  'postType',
  'containerId',
  'sort',
]);

export const followingMemberPostsListCollectionId = parameterizedCollectionId(
  'followingMemberPostsList',
  ['tags', 'onlyCurrentUser', 'postType', 'containerId', 'sort'],
);

/**
 * The collection of posts related to a given post
 * @function communityRelatedPostsCollectionId
 * @param params {object}
 * @param params.postId {string} The id of the post
 */
export const communityRelatedPostsCollectionId = parameterizedCollectionId(
  'communityRelatedPosts',
  ['postId'],
);

/**
 * The collection of posts in a specific Slimming Group
 * @function groupPostsCollectionId
 * @param params {object}
 * @param params.containerId {string} The id of the Slimming Group
 */
export const groupPostsCollectionId = parameterizedCollectionId('groupPosts', ['containerId']);

/**
 * The collection of posts visible on the community group page
 * @function communityGroupPostsCollectionId
 * @param params {object}
 * @param [params.slug] {string} Slug of the community group
 * @param [params.tags] {number} A number representing the active tag filters (bitwise flag)
 * @param [params.onlyCurrentUser] {boolean} `true` if the 'only me' filter is active
 * @param [params.postType] {string} Filter by post type
 * @param [params.containerId] {string} Id of the containing post
 * @param [params.sort] {string} The sorting type used
 */
export const communityGroupPostsCollectionId = parameterizedCollectionId('communityGroupPosts', [
  'slug',
  'tags',
  'onlyCurrentUser',
  'postType',
  'containerId',
  'sort',
]);

/**
 * The collection of comments on a discussion or another comment
 * @function communityCommentsCollectionId
 * @param params {object}
 * @param params.parentId {string} The id of the parent discussion or comment
 * @param [params.parentIsComment] {boolean} `true` if the parent is a comment. Otherwise the parent
 * is a discussion
 * @param [params.deeplink] {boolean} `true` if the this is a collection for a deeplink, meaning
 * that the pagination of this collection will be a partition rather than offset-based pagination
 */
export const communityCommentsCollectionId = parameterizedCollectionId('communityComments', [
  'parentId',
  'parentIsComment',
  'deeplink',
]);

/**
 * Subscription packages available for purchase when creating an account
 * @type {string}
 */
export const SUBSCRIPTION_PACKAGES = 'subscriptionPackages';

/**
 * Free Food Allowance available for syns estimator
 * @type {string}
 */
export const FREE_FOOD_ALLOWANCE = 'freeFoodAllowance';

/**
 * The collection of awards for a specific user
 * @function awardsCollectionId
 * @param params {object}
 * @param params.userId {string}
 */
export const awardsCollectionId = parameterizedCollectionId('awards', ['userId']);
export const awardsProfileCollectionId = parameterizedCollectionId('awardsCommunity', ['userId']);
export const learningAwardsCollectionId = parameterizedCollectionId('learningAwards', ['userId']);
/**
 * The collection of goals for a specific user
 * @function goalsCollectionId
 * @param params {object}
 * @param params.userId {string}
 */
export const goalsCollectionId = parameterizedCollectionId('goals', ['userId']);

/**
 * List of data for each week of the 12 week program
 * @type {string}
 */
export const WEEKS_META_DATA = 'weeksMetaData';

/**
 * @type {string}
 */
export const FEATURES = 'features';

/**
 * @type {string}
 */
export const SUCCESS_STORIES = 'successStories';

/**
 * @type {string}
 */
export const RECIPES = 'recipes';

/**
 * List of search result from a given search query
 * @function searchResultsCollectionId
 * @param params {object}
 */
export const searchResultsCollectionId = parameterizedCollectionId(
  'searchResults',
  [
    'tags',
    'type',
    'offset',
    'limit',
    'sort',
    'query',
    'foodOptimizing',
    'activityType',
    'segmentDuration',
    'bodyMagicLevel',
    'dietaryPreferences',
  ].concat(allFilterKeys),
);

/**
 * List of promoted search result from a given search query
 * @function promotedSearchResultsCollectionId
 * @param params {object}
 */
export const promotedSearchResultsCollectionId = parameterizedCollectionId(
  'promotedSearchResults',
  ['query'].concat(allFilterKeys),
);

export const userWeighInsCollectionId = parameterizedCollectionId('userWeighIns', ['userId']);

export const userWeighInHistoryCollectionId = parameterizedCollectionId('userWeighInHistory', [
  'userId',
]);

export const PAYMENT_COLLECTION_ID = 'payment';

/**
 * Cover photos which can be picked for a users profile
 * @type {string}
 */
export const COVER_PHOTOS = 'coverPhotos';

/**
 * List of food result from a given search query
 * @function foodResultsCollectionId
 * @param params {object}
 * @param params.filters {string}
 * @param params.limit {number}
 * @param params.offset {number}
 * @param params.query {string}
 */
export const foodPlannerResultsCollectionId = parameterizedCollectionId('foodPlanner', [
  'filters',
  'limit',
  'offset',
  'query',
]);

/**
 * List of custom food result from a given search query
 * @function customFoodResultsCollectionId
 * @param params {object}
 * @param params.filters {string}
 * @param params.limit {number}
 * @param params.offset {number}
 * @param params.query {string}
 */
export const customFoodResultsCollectionId = parameterizedCollectionId('customFoodPlanner', [
  'filters',
  'limit',
  'offset',
  'query',
]);

/**
 * Collection of meal categories for a specific user
 * @function  mealCategoriesCollectionId
 * @param params {object}
 * @param params.userId {string}
 * @param params.limit {number}
 */
export const mealCategoryCollectionId = parameterizedCollectionId('mealCategory', [
  'userId',
  'limit',
]);

/**
 * List of purchase result
 * @function purchaseResultsCollectionId
 * @param params {object}
 */
export const purchaseResultsCollectionId = parameterizedCollectionId('purchaseResults', [
  'offset',
  'limit',
  'fullSnapShot',
]);

/**
 * Purchase result for member account setting menu display
 * @function menuPurchaseResultsCollectionId
 * @param params {object}
 */
export const menuPurchaseResultsCollectionId = parameterizedCollectionId('menuPurchaseResults', [
  'offset',
  'limit',
  'fullSnapShot',
]);

/**
 * List of return result
 * @function returnResultsCollectionId
 * @param params {object}
 */
export const returnResultsCollectionId = parameterizedCollectionId('returnResults', [
  'userId',
  'purchaseItemIds',
]);

/**
 * Collection of activity video search results
 * @function  activityVideos
 * @param params {object}
 * @param params.query {string}
 * @param params.limit {number}

 */
export const activityVideosCollectionId = parameterizedCollectionId('activityVideos', [
  'query',
  'limit',
]);

/**
 * Collection of user logged activities for a specific user
 * @function  activities
 * @param params {object}
 * @param params.userId {string}

 */
export const activitiesCollectionId = parameterizedCollectionId('myPlannedActivities', ['userId']);

/**
 * Collection of recent user logged activities for a specific user
 * @function  activities
 * @param params {object}
 * @param params.userId {string}

 */
export const recentActivitiesCollectionId = parameterizedCollectionId('myRecentActivities', [
  'userId',
]);

/**
 * Collection of search result activity types for a specific user
 * @function  activityTypes
 * @param params {object}
 * @param params.limit {number}
 * @param params.profileId {string}
 * @param params.searchText {string}

 */
export const activityTypesCollectionId = parameterizedCollectionId('activityTypes', [
  'limit',
  'profileId',
  'searchText',
]);

/**
 * Collection of Body Magic awards for a specific user
 * @function  activityGoals
 * @param params {object}
 * @param params.userId {string}
 * @param params.startDate {string}
 */
export const activityGoalCollectionId = parameterizedCollectionId('myActivityGoals', [
  'profileId',
  'startDate',
  'status',
]);

/**
 * User favourites
 */
export const FAVOURITES = 'favourites';

/**
 * Irish counties available during the group registration flow
 * @type {string}
 */
export const IRISH_COUNTIES = 'irishCounties';
export const COUNTRY_REGIONS = 'countryRegions';

/**
 * Collection of products
 * @function  productCollectionId
 * @param params {object}
 * @param params.limit {number}
 * @param params.category {number}
 */
export const productCollectionId = parameterizedCollectionId('product', [
  'limit',
  'category',
  'regionCode',
]);

/**
 * Collection of products
 * @function  productCollectionId
 * @param params {object}
 * @param params.regionCode {number}
 */
export const packageCollectionId = parameterizedCollectionId('package', ['regionCode']);

/**
 * PublicRecipes
 * @type {string}
 */
export const PUBLIC_RECIPES = 'publicRecipes';
export const PUBLIC_RECIPE = 'publicRecipe';
export const PUBLIC_SUCCESS_STORIES = 'publicSuccessStories';

/**
 * Collection of healthy extra search
 * @function  mealCategoriesCollectionId
 * @param params {object}
 * @param params.userId {string}
 * @param params.limit {number}
 */
export const healthyExtrasCollectionId = parameterizedCollectionId(
  HEALTHY_EXTRAS,
  Object.values(HealthyExtrasFields),
);

/**
 * List of meta data for each of the different reading sections
 * @type {string}
 */
export const RECOMMENDED_READING_META_DATA = 'recommendedReadingMetaData';

/**
 * @type {string}
 */
export const CAMPAIGNS = 'campaigns';

/**
 * @type {string}
 */
export const BANNERS = 'banners';

export const COMMUNITY_GROUPS = 'communityGroups';
export const COMMUNITY_MEMBER_GROUPS = 'communityMemberGroups';

export const followingListCollectionId = parameterizedCollectionId('followingList', ['userId']);
export const followersListCollectionId = parameterizedCollectionId('followersList', ['userId']);
export const memberPostsListCollectionId = parameterizedCollectionId('memberPostsList', ['userId']);

export const memberPodcastCollectionId = parameterizedCollectionId('memberPodcast', [
  'category',
  'sort',
  'limit',
  'offset',
]);

/**
 * List of cooldown videos
 * @function cooldownVideoCollectionId
 * @param params {object}
 */
export const cooldownVideoCollectionId = parameterizedCollectionId('cooldownVideos', [
  'offset',
  'limit',
]);

/**
 * Package features - used for the online registration flow
 * @type {string}
 */
export const PACKAGE_FEATURES = 'packageFeatures';

/**
 * List of subscription purchase results
 * @function subscriptionPurchaseResultsCollectionId
 * @param params {object}
 */
export const subscriptionPurchaseResultsCollectionId = parameterizedCollectionId(
  'subscriptionPurchaseResults',
  [],
);

/**
 * List of external subscription purchase results
 * @function externalSubscriptionPurchaseResultsCollectionId
 * @param params {object}
 */
export const externalSubscriptionPurchaseResultsCollectionId = parameterizedCollectionId(
  'externalSubscriptionPurchaseResults',
  [],
);
