import { connect } from 'react-redux';
import { compose } from 'redux';
import { push as historyPush } from 'react-router-redux';
import { subscriptionPackagesSelector } from 'common/src/app/selectors/packageVoucherSelectors';
import Pages from 'common/src/app/data/enum/Pages';
import { getPackages } from 'common/src/app/actions/resources/shopActions';
import { adyenClientResponse } from 'common/src/app/actions/resources/paymentActions';
import { AdyenTransactionResultCode } from 'common/src/app/adyen/types/AdyenTransactionResultCode';
import PaymentForm from './PaymentForm';

const mapStateToProps = (state: any) => {
  const purchaseInfo =
    typeof sessionStorage !== 'undefined' &&
    sessionStorage.getItem('purchaseId') &&
    JSON.parse(sessionStorage.getItem('purchaseId') as string);

  return {
    selectedPackage:
      purchaseInfo?.package && subscriptionPackagesSelector(state, purchaseInfo.package),
    publicHost: state.config.environmentConfig.web.public.host,
    accountHost: state.config.environmentConfig.web.account.host,
    purchaseInfo,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  getPackagesForRegion: (regionCode: number) => {
    dispatch(getPackages({ regionCode }));
  },
  onlineRegistrationRedirect: () =>
    dispatch(
      historyPush({
        pathname: `${Pages.REGISTRATION}`,
      }),
    ),
  paymentResponse: (purchaseId: string, resultCode: AdyenTransactionResultCode) =>
    dispatch(adyenClientResponse(purchaseId, resultCode)),
  paymentSuccessRedirect: (purchaseId?: string, packageType?: string) => {
    dispatch(
      historyPush({
        pathname: `${Pages.REGISTRATION_FINISHED}?purchaseId=${purchaseId}&packageType=${packageType}`,
      }),
    );
    return sessionStorage.removeItem('purchaseId');
  },
});

const connected = connect(mapStateToProps, mapDispatchToProps);

export default compose(connected)(PaymentForm);
