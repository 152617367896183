import React, { useRef, RefObject } from 'react';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';

import LocaleMessage from 'components/atoms/LocaleMessage';
import Button from 'components/atoms/Button';
import TextNew, { TextTypes, Colors } from 'components/atoms/TextNew';

import './adyen-payment-error.scss';
import useScrollToElement from 'common/src/app/hooks/useScrollToElement';

type AdyenPaymentErrorProps = {
  paymentStatus: string;
  error: 'generalError' | 'sessionError' | 'invalidRecaptchaToken' | 'cancelError';
  link: string;
  authFailed: boolean;
  scrollTo: boolean;
  subscription?: boolean;
};

const AdyenErrorMessage = ({ error }: { error: string }): JSX.Element => (
  <TextNew
    dataTestid="payment-error"
    type={TextTypes.ERROR}
    color={Colors.BRAND}
    localeId={`adyenPaymentError.description.${error}`}
  />
);

const AdyenPaymentError = (
  { paymentStatus, error, link, authFailed, scrollTo, subscription }: AdyenPaymentErrorProps,
  {},
  className: string,
): JSX.Element => {
  const errorRef: RefObject<HTMLDivElement> = useRef(null);
  useScrollToElement(errorRef, scrollTo);

  return (
    <div ref={errorRef} role="alert" className={className}>
      {/* Hiding the error when a member closes the digital wallet ui as not a genuine error */}
      {error && error !== 'cancelError' ? <AdyenErrorMessage error={error} /> : null}
      {paymentStatus ? <AdyenErrorMessage error="paymentError" /> : null}

      {authFailed && (
        <Button
          onClick={() =>
            subscription
              ? (window.location.href = `${window.location.origin}${window.location.pathname}?addPayment=true`)
              : (window.location.href = window.location.origin + window.location.pathname)
          }
        >
          <LocaleMessage id="adyenPaymentError.button" />
        </Button>
      )}

      {authFailed === false && error && error === 'sessionError' && (
        <Button onClick={() => null} to={link}>
          <LocaleMessage id="adyenPaymentError.button" />
        </Button>
      )}

      {authFailed === false && error && error === 'invalidRecaptchaToken' && (
        <Button onClick={() => window.location.reload()} to={null}>
          <LocaleMessage id="adyenPaymentError.button" />
        </Button>
      )}
    </div>
  );
};

export default withFunctionalClassName(
  ComponentType.MOLECULE,
  'AdyenPaymentError',
)(AdyenPaymentError);
